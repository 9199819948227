import React, { useState, useEffect } from "react";
import siteplanImage from 'src/assets/images/siteplan-full.jpg';
import { Link } from "react-router-dom";
import "./Siteplan.css";
import './../Loader/Loader.css';
import { fetchHouseData } from './../../api/api.js';

const Siteplan = ({ isApplyFilter, filterData }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [houseInfo, setHouseInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [houseData, setHouseData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = {
          filterAvailable: filterData.filterAvailable,
          filterSoldOut: filterData.filterSoldOut,
          filter81to100: filterData.filter81to100,
          filter41to80: filterData.filter41to80,
          filter0to40: filterData.filter0to40,
          filter9072: filterData.filter9072,
          filter7660: filterData.filter7660,
          filter7260: filterData.filter7260,
          filterPhase1: filterData.filterPhase1,
          filterPhase2: filterData.filterPhase2,
          filterPhase3: filterData.filterPhase3,
        };
  
        const data = await fetchHouseData(queryParams);
        const updatedData = data.map((house) => ({
          ...house,
          statusText: house.status === 1 ? 'Available' : 'Sold Out',
          typeText: house.type === 1 ? '72/60' : house.type === 2 ? '80/60' : '90/72',
          progressText: `Progress: ${house.progress}%`,
          phaseText: house.phase === 1 ? 'Phase 1' : house.phase === 2 ? 'Aesthetic' : 'Phase 3',
        }));
        setHouseData(updatedData);
      } catch (error) {
        console.error('Error fetching house data:', error);
      }
    };
  
    fetchData();
  }, [filterData]);
  
  const handleMarkerClick = (x, y, houseId, statusText, typeText, progressText, phaseText) => {
    console.log("Marker clicked:", x, y, houseId, statusText, typeText, progressText, phaseText);
    setTooltipVisible(true);
    setHouseInfo({
      id: houseId,
      statusText,
      typeText,
      progressText,
      phaseText,
    });
    setTooltipPosition({ x, y });
  };

  useEffect(() => {
    const handleResize = () => {
      const image = document.querySelector(".siteplan-image");
      if (image) {
        const { width, height } = image.getBoundingClientRect();
        setImageSize({ width, height });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="siteplan-container">
      <div className="image-container">
        <img
          src={siteplanImage}
          alt="test"
          className="siteplan-image"
          onLoad={() => {
            const image = document.querySelector(".siteplan-image");
            const { width, height } = image.getBoundingClientRect();
            setImageSize({ width, height });
          }}
        />
        {houseData.map((house, index) => (
          <div
            key={house._id || index}
            className={`marker ${house.status === 1 ? "available" : "sold"}`}
            style={{
              top: `${house.y * imageSize.height}px`,
              left: `${house.x * imageSize.width}px`,
              transformOrigin: "center",
            }}
            onClick={() =>
              handleMarkerClick(
                house.x,
                house.y,
                house.id,
                house.statusText,
                house.typeText,
                house.progressText,
                house.phaseText,
              )
            }
          ></div>
        ))}

        {tooltipVisible && houseInfo && (
          <div
            className="tooltip"
            style={{
              top: `${tooltipPosition.y * imageSize.height + -10}px`,
              left: `${tooltipPosition.x * imageSize.width}px`,
              transform: "translate(-50%, -100%)",
              visibility: "visible",
              pointerEvents: "auto",
            }}
          >
            <p>{houseInfo.progressText}</p>
            <p>Status: {houseInfo.statusText}</p>
            <p>Type: {houseInfo.typeText}</p>
            <p>Phase: {houseInfo.phaseText}</p>

            <Link to={`/detail/${houseInfo && houseInfo.id}`} className="tooltip-link">
              Detail Spesifikasi
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Siteplan;
